import React, { FC, useMemo, useState } from 'react';
import { cn } from '@/lib/classNames';
import { ReviewFragment } from '@/controllers/graphql/generated';
import { section } from '@/components/ui/section';
import { typography } from '@/components/ui/typography';
import { Button } from '@/components/ui/Button';
import { IconChevronLeft } from '@/components/ui/icons/IconChevronLeft';
import { IconChevronRight } from '@/components/ui/icons/IconChevronRight';
import { VideoReviewsSlider } from '@/components/landing/ReviewsBlock/components/VideoReviewsSlider';
import { useFeatures } from '@/controllers/features/features.hooks/useFeatures';
import { Features } from '@/controllers/features/features.constants';
import { useTranslation } from '@/middleware/i18n';
import { I18N_CODES } from '@/lib/constants/general';
import { useStateRef } from '@/hooks/useStateRef';
import { TextReview } from '@/controllers/reviews/reviews.typedefs';
import { TextReviewsSlider } from './components/TextReviewsSlider';
import { VideoReview } from './components/VideoReviewsSlider/VideoReviewItem';
import styles from './ReviewsBlock.module.scss';

enum ReviewTabType {
  Text = 'text',
  Video = 'video'
}

interface Props {
  id?: string;
  reviews?: ReviewFragment[];
  textReviews?: TextReview[];
  title: string;
}

export const ReviewsBlock: FC<Props> = (props) => {
  const {
    reviews,
    title,
    id,
    textReviews,
  } = props;

  const { t } = useTranslation([I18N_CODES.reviews]);

  const features = useFeatures();

  const {
    video: videoReviews,
    text: textReviewsDeprecated,
  } = useMemo(() => {
    const video: VideoReview[] = [];
    const text: TextReview[] = [];

    (reviews || []).forEach((review) => {
      if (review.videoLink) {
        video.push({
          id: review.id,
          username: review.authorUsername,
          jobTitle: review.authorJobTitle ?? undefined,
          videoLink: review.videoLink,
          posterLink: review.photo?.url,
        });
      } else {
        text.push({
          id: review.id,
          name: review.authorUsername,
          jobTitle: review.authorJobTitle ?? undefined,
          content: review.comment,
          createdAt: review.createdAt,
          sourceUrl: review.sourceLink ?? undefined,
          avatar: review.photo ?? review.authorAvatar ?? undefined,
        });
      }
    });

    return {
      video,
      text,
    };
  }, [reviews]);

  const textReviewsList = textReviews || textReviewsDeprecated;

  const shouldRenderVideoReviews = (
    features.isEnabled(Features.VideoReviewsSection)
    && videoReviews.length > 0
  );

  const shouldRenderTextReviews = textReviewsList.length > 0;

  const shouldRenderTabs = shouldRenderTextReviews && shouldRenderVideoReviews;

  const [activeTab, setActiveTab] = useState(() => {
    if (shouldRenderTabs) {
      return ReviewTabType.Video;
    }

    if (shouldRenderVideoReviews) {
      return ReviewTabType.Video;
    }

    return ReviewTabType.Text;
  });

  const [prevButton, setPrevButtonRef] = useStateRef<HTMLButtonElement>();
  const [nextButton, setNextButtonRef] = useStateRef<HTMLButtonElement>();

  return (
    <section
      id={id}
      className={cn({ [section.scrollSection]: Boolean(id) })}
    >
      <div className="overflow-hidden">
        <div className="grid-container">
          <div className={cn(
            styles.headingContainer,
            'grid-x grid-margin-x align-bottom mb-76 medium-mb-64 small-mb-24',
          )}
          >
            <div className="cell large-7 medium-9 small-mb-56">
              <h2
                className={cn(typography.landingH2)}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </div>

            <div
              className="cell large-5 medium-3 small-12 flex-container align-self-bottom align-right"
            >
              <Button
                className="mr-16"
                LeftIcon={IconChevronLeft}
                mode={Button.mode.Secondary}
                size={Button.size.Large}
                ref={setPrevButtonRef}
                data-qa="previous-slide"
              />
              <Button
                LeftIcon={IconChevronRight}
                mode={Button.mode.Secondary}
                size={Button.size.Large}
                ref={setNextButtonRef}
                data-qa="next-slide"
              />
            </div>

            <div className="cell large-3 medium-6">
              {shouldRenderTabs && (
                <div className={styles.tabButtonsWrapper}>
                  <Button
                    mode={Button.mode.Secondary}
                    size={Button.size.Large}
                    text={t(`${I18N_CODES.reviews}:video_reviews_button`)}
                    onClick={() => {
                      setActiveTab(ReviewTabType.Video);
                    }}
                    className={cn(
                      styles.tabButton,
                      {
                        [styles.tabButtonActive]:
                        activeTab === ReviewTabType.Video,
                      },
                    )}
                  />
                  <Button
                    mode={Button.mode.Secondary}
                    size={Button.size.Large}
                    text={t(`${I18N_CODES.reviews}:text_reviews_button`)}
                    onClick={() => {
                      setActiveTab(ReviewTabType.Text);
                    }}
                    className={cn(
                      styles.tabButton,
                      {
                        [styles.tabButtonActive]:
                        activeTab === ReviewTabType.Text,
                      },
                    )}
                  />
                </div>
              )}
            </div>
          </div>

          {activeTab === ReviewTabType.Video && (
            <VideoReviewsSlider
              prevButton={prevButton}
              nextButton={nextButton}
              items={videoReviews}
            />
          )}

          {activeTab === ReviewTabType.Text && (
            <TextReviewsSlider
              prevButton={prevButton}
              nextButton={nextButton}
              items={textReviewsList}
            />
          )}
        </div>
      </div>
    </section>
  );
};
