import React, { FC } from 'react';
import { cn } from '@/lib/classNames';
import { typography } from '@/components/ui/typography';
import { FastAnswerQuestionBaseFragment } from '@/controllers/graphql/generated';
import { section } from '@/components/ui/section';
import { FAQItem } from '@/components/landing/FAQBlock/FAQItem';

interface Props {
  id?: string;
  items: FastAnswerQuestionBaseFragment[];
  title: string;
}

export const FAQBlock: FC<Props> = (props) => {
  const { id, items, title } = props;

  return (
    <section
      data-qa="faq-landing-block"
      id={id}
      className={cn({ [section.scrollSection]: Boolean(id) })}
    >
      <div className="grid-container">
        <div className="grid-x grid-margin-x align-center-middle">
          <div className="cell mb-84">
            <h2
              className={cn(typography.landingH2, 'text-center margin-auto')}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </div>

          <div className="cell large-8">
            {items.map((item) => (
              <FAQItem key={item.id} item={item} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
